<template>
  <div class="row fit q-py-md">
    <div class="col col-2 q-pl-lg">
      <Filters model="dashboard" color="per" />
    </div>

    <div class="col">
      <div class="column q-pa-lg q-gutter-lg">
        <div class="col" v-if="dashboardData.comptage_reseau">
          <div class="row justify-between items-center">
            <h6>{{$t('per.dashboard.comptage_reseau.title')}}</h6>
            <div>
              <q-btn class="q-ml-md" color="per" size="sm" outline :to="{ name: 'entities-list' }">Gestion du réseau</q-btn>
              <q-btn class="q-ml-md" color="per" size="sm" outline :to="{ name: 'cibles-list' }">Gestion des cibles</q-btn>
              <q-btn class="q-ml-md" color="per" size="sm" :to="{ name: 'cible-create' }">Créer une cible</q-btn>
            </div>
          </div>

          <div class="row">
            <q-list class="row fit bg-white q-py-md">
              <q-item class="col col-2 head-item">
                <q-item-section>
                  <q-item-label header>{{$t('per.dashboard.comptage_reseau.entites')}}</q-item-label>
                  <q-item-label header class="text-h6">{{dashboardData.comptage_reseau.entites}}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item class="col" v-for="field in ['agents', 'wallets', 'point_de_vente', 'mandats_gestion']" :key="field">
                <q-item-section>
                  <q-item-label header>{{$t(`per.dashboard.comptage_reseau.${field}`)}}</q-item-label>
                  <q-item-label>{{dashboardData.comptage_reseau[field]}}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>

        <div v-for="meteo in [ 'cibles_EN_COURS', 'cibles_TERMINE', 'cibles_ABANDONNE',
          'sous_cibles_EN_COURS', 'sous_cibles_TERMINE', 'sous_cibles_ABANDONNE' ]"
          :key="meteo" :set="data = getMeteo(meteo)">
          <div class="col" v-if="data">
            <h6>{{$t(`per.dashboard.${meteo}.title`)}}</h6>
            <div class="row">
              <q-list class="row fit bg-white q-py-md">
                <q-item class="col col-2 head-item">
                  <q-item-section class="items-center">
                    <q-item-label header>{{$t(`per.dashboard.${meteo}.cibles`)}}</q-item-label>
                    <q-item-label header class="text-h6">{{data.total}}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item class="col" v-for="count in counts" :key="count.label">
                  <q-item-section class="items-center">
                    <q-item-label header><q-icon :name="count.icon" /></q-item-label>
                    <q-item-label>{{data[count.label]}}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </div>

        <div class="col bg-white row">
          <q-list class="col col-2 q-py-md" dense>
            <q-item>
              <q-item-section>
                <q-item-label header>Légende</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-for="count in counts" :key="count.label" class="items-end">
              <q-item-section class="col col-auto q-px-md">
                <q-item-label><q-icon :name="count.icon" /></q-item-label>
              </q-item-section>
              <q-linear-progress class="col" :value="1" :color="count.color" style="height: 0.8rem" />
            </q-item>
          </q-list>

          <div class="col q-py-md q-gutter-y-lg" v-if="dashboardData.comptage">
            <q-list v-for="(data, key) in dashboardData.comptage" v-bind:key="key">
              <q-item>
                <q-item-section>
                  <q-item-label header>{{$t(`per.dashboard.${key}.${key}`)}}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <div class="row items-end q-py-xs" v-for="datum in data" v-bind:key="datum.label">
                    <q-item-label class="col col-3" lines=1>{{$t(`per.dashboard.${key}.${datum.label}`)}}</q-item-label>
                    <q-item-label class="col col-1 text-center">{{counts.reduce((sum, val) => sum + datum.counts[val.label], 0)}}</q-item-label>
                    <div class="col">
                      <div class="row">
                        <q-linear-progress v-for="count in counts" v-bind:key="count.label"
                          :value="1" :color="count.color"
                          :style="`height: 0.8rem; width: ${datum.counts[count.label] / maxCount * 100}%`" />
                      </div>
                    </div>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Filters } from '../../components/filters'

export default {
  name: 'DashboardPage',
  components: { Filters },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['dashboard/getFilters']) {
      store.dispatch('dashboard/fetchFilters')
    }
  },

  data () {
    return {
      counts: [
        { label: 'bien', color: 'per', icon: 'wb_sunny' },
        { label: 'moyen', color: 'warning', icon: 'wb_cloudy' },
        { label: 'mal', color: 'negative', icon: 'flash_on' },
        { label: 'mort', color: 'purple-8', icon: 'thumb_down' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      dashboardData: 'dashboard/getDashboardData',
      filters: 'dashboard/getFilters'
    }),
    maxCount () {
      if (!this.dashboardData || !this.dashboardData.comptage) return Number.POSITIVE_INFINITY
      let count = 0
      Object.keys(this.dashboardData.comptage).forEach(key => {
        this.dashboardData.comptage[key].forEach(({ label, counts }) => {
          if (counts.total && counts.total > count) { count = counts.total }
        })
      })
      return count > 0 ? count : Number.POSITIVE_INFINITY
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.fetchData()
      },
      deep: true
    }
  },

  mounted () {
    this.$store.commit('pages/setPageTitle', this.$t('global.per.dashboard'))

    if (this.filters) {
      this.fetchData()
    }
  },

  methods: {
    fetchData (date_projetee) {
      this.loading = true
      let params = {}
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      return this.$store.dispatch('dashboard/fetchDashboard', params)
        // .catch(error => { console.log(error) })
        .finally(() => { this.loading = false })
    },

    getMeteo (meteo) {
      return !!this.dashboardData && this.dashboardData[meteo]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-item__label--header
    padding: 0

  .comptage-list
    padding: 1rem 0

  .head-item
    border-right: 1px solid $grey-4
</style>
